import React, { useEffect, useState } from 'react'
import styles from '../../../../../styles/modal/ModalStudent.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { saveProvitionalQuestionsAcademic } from '../../../../../app/features/Applicants/applicantsSlice';

export default function TabAcademicInformation( {title} ) {

    const { listApplicants, activeApplicant , provitionalQuestionsAcademic } = useSelector(state => state.applicants);
    const [answersAcademic,setAnswersAcademic] = useState(activeApplicant.questionary__academic__information);
    // const [answersAcademic,setAnswersAcademic] = useState({

    //     // 'questionaryId' : activeApplicant.questionary__academic__information.id,
    //     // 'collegeQuestion' : provitionalQuestionsAcademic.collegeQuestion ?? activeApplicant.questionary__academic__information.collegeQuestion ?? null,
    //     // 'locationQuestion' : provitionalQuestionsAcademic.locationQuestion ?? activeApplicant.questionary__academic__information.locationQuestion ?? null,
    //     // 'rbForeignQuestion' : provitionalQuestionsAcademic.rbForeignQuestion ?? activeApplicant.questionary__academic__information.rbForeignQuestion ?? null,
    //     // 'fieldRequired': 1


    // });

    const dispatch = useDispatch();


    useEffect(() =>{

        dispatch ( saveProvitionalQuestionsAcademic ( answersAcademic , 'Saving provitional Questions Academic'));

    },[answersAcademic])


   

    const handleChange =  (e) => {


        setAnswersAcademic({
            ...answersAcademic,
            [e.target.name] : e.target.value
         });

    }

    return (
        <div>

            <div className={styles.boxQuestions}>
                <h1 className={styles.titleQuestions} >{ activeApplicant.questionary__academic__information.title }</h1>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Nombre de colegio actual *</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="currentCollegeName"  value={answersAcademic.currentCollegeName} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>



            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Fecha de ingreso a la institución *</label>
                    </div>
                    <div className={styles.inputBox}>
                    <input type="date" id="start"  name="yearGetIn" value={answersAcademic.yearGetIn} onChange={(e) =>  handleChange(e)}  />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>⁠Idioma de instrucción en el colegio actual*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="languagefromCurrentCollege"  value={answersAcademic.languagefromCurrentCollege} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>⁠Dirección</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="email" name="address"  value={answersAcademic.address} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Nombre del director</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="email" name="nameAdministratorCollege"  value={answersAcademic.nameAdministratorCollege} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Grado actual*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <select name="currentGrade"  value={answersAcademic.currentGrade}  onChange={(e) =>  handleChange(e)} id="currentGrade-select">
                        <option value=""></option>
                        <option value="6to">6to de primaria</option>
                        <option value="1erosecu">1ero de secundaria</option>
                            <option value="2dosecu">2do de secundaria</option>
                            <option value="3erosecu">3ero de secundaria</option>
                            <option value="1eroprepa">1ero de preparatoria</option>
                            <option value="2doprepa">2do de preparatoria</option>
                            <option value="3roprepa">3ro de preparatoria</option>
                        </select>
                        </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Grado que desea aplicar*</label>
                    </div>
                    <div className={styles.inputBox}>
                    <select name="gradewished"  value={answersAcademic.gradewished}  onChange={(e) =>  handleChange(e)} id="gradeWished-select">
                    <option value=""></option>

                            <option value="1erosecu">1ero de secundaria</option>
                            <option value="2dosecu">2do de secundaria</option>
                            <option value="3erosecu">3ero de secundaria</option>
                            <option value="1eroprepa">1ero de preparatoria</option>
                            <option value="2doprepa">2do de preparatoria</option>
                            <option value="3roprepa">3ro de preparatoria</option>
                            <option value="pg">PG</option>
                        </select>                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label> Ciclo escolar en que desee aplicar*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="ciclyWished"  value={answersAcademic.ciclyWished} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Tiempo de estudio*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="durationStudy"  value={answersAcademic.durationStudy} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>He repetido año ( grado que repitio ) </label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="yearRepetead"  value={answersAcademic.yearRepetead} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Nombre y grados de estudios en colegios anteriores</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="nameAndGradeBefore"  value={answersAcademic.nameAndGradeBefore} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>




            {/* <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Ha repetido año ? </label>
                    </div>
                    <div className={styles.inputBox}>
                        <fieldset>

                            <div>
                                <input type="radio" id="huey" onClick={(e) => handleChange(e)} name="rbForeignQuestion" value="true" checked={answersAcademic.rbForeignQuestion ?? 0}  />
                                <label style={{ marginLeft: '5px' }} for="huey">Yes</label>
                            </div>

                            <div>
                                <input type="radio" id="dewey" onClick={(e) => handleChange(e)} name="rbForeignQuestion" value="false" checked={answersAcademic.rbForeignQuestion == true ? false : true} />
                                <label style={{ marginLeft: '5px' }} for="dewey">No</label>
                            </div>


                        </fieldset>

                    </div>
                </div>
            </div> */}




        </div>
    )
}
