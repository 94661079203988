import { Navigate, Route, Routes } from 'react-router-dom';
import Admision from './pages/AdmisionPage/Admission/Admision.jsx';
import './App.css';
import LandingPage from './pages/LandingPage/LandingPage';
import ProtectedRoutes from './ProtectedRoutes/ProtectedRoutes.jsx';
import PublicRoutes from './PublicRoutes/PublicRoutes.jsx';

// @fonts
import  "./fonts/montse/Montserrat-VariableFont_wght.ttf"


// router here 

function App() {
  return (

    <Routes>
    <Route exact path="/" element={
        <Navigate to="/AdmissionPortal" />
      } />
      
     <Route element={ <ProtectedRoutes/>}>
            <Route exact path="/AdmissionPortal/Landing" element={<LandingPage/>} />

     </Route>

     <Route element={ <PublicRoutes/>}>
     <Route exact path="/AdmissionPortal" element={<Admision/>} />

    
     </Route>

    
    
    </Routes>

  );
}

export default App;
