import { configureStore } from "@reduxjs/toolkit"
import  sessionReducer from "./features/Session/sessionSlice";
import  applicantsReducer from "./features/Applicants/applicantsSlice";

const store = configureStore( { 

    
    reducer: { 

        sessionAuth: sessionReducer,
        applicants : applicantsReducer

    },
})


export default store;