import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import modalLogo from '../../../../files/BSM Logo negro fondo transparente.png'
import styles from '../../../../styles/modal/ModalStudent.module.css'
import TabAcademicInformation from './tabsModalStudent/TabAcademicInformation';
import { useSelector } from 'react-redux';
import TabStudentInformation from './tabsModalStudent/TabStudentInformation';
import TabFamilyInformation from './tabsModalStudent/TabFamilyInformation';
import TabProfileInformation from './tabsModalStudent/TabProfileInformation';
import axios from 'axios';

export default function ModalStudent(props) {

  const { provitionalQuestionsAcademic, provitionalQuestionsStudentInformation, provitionalQuestionsFamily, provitionalQuestionsStudentProfile } = useSelector(state => state.applicants);


  useEffect(() => {
    setHeaderActive(props.header);

  }, [props.header])


  const saveAnswers = async (url, obj) => {

    try {
      const response = await axios.post('https://xtendapi.boardingschools.mx/api/applicants/' + url, obj);

      // dispatch(setApplicantsList(response.data, 'ListApplicants'))

      // alert('Aplicante creado con exito.')

      // props.closeModalNewStudent(false);

    } catch (error) {
      alert('Ocurrio un error');
    }

  }

  const [newStudent, setNewStudent] = useState({});

  const handleClose = () => {



    const cadn = "¿ Realmente desea salir ? La información no guardada se perdera.";
    const answer = window.confirm(cadn);

    if (answer) {
        try {
          props.closeModal(newStudent);

        } catch (error) {
            alert('Ocurrio un error');
        }
    }
    else {
        //some code
    }




    // props.getStudent(props.getStudent);
    // saveAnswers('provitionalQuestionsAcademic', provitionalQuestionsAcademic);
    // saveAnswers('provitionalQuestionsStudentInformation', provitionalQuestionsStudentInformation);
    // saveAnswers('provitionalQuestionsFamily', provitionalQuestionsFamily);
    // saveAnswers('provitionalQuestionsStudentProfile', provitionalQuestionsStudentProfile);
    // props.closeModal(newStudent);

  }
  const headers = ['INFORMACIÓN DEL ESTUDIANTE', 'INFORMACIÓN ACADEMICA', 'INFORMACIÓN FAMILIAR', 'PERFIL DEL ESTUDIANTE'];
  const [headerActive, setHeaderActive] = useState('INFORMACIÓN DEL ESTUDIANTE');



  // Validations

  // Validation from student information

  const validationsFromTabStudentInformation = async() => {

    if (
      provitionalQuestionsStudentInformation.firstNameQuestion == "" || provitionalQuestionsStudentInformation.firstNameQuestion == null
      || provitionalQuestionsStudentInformation.father_lastnameQuestion == "" || provitionalQuestionsStudentInformation.father_lastnameQuestion == null
      || provitionalQuestionsStudentInformation.birthdayQuestion == "" || provitionalQuestionsStudentInformation.birthdayQuestion == null
      || provitionalQuestionsStudentInformation.nationalityQuestion == "" || provitionalQuestionsStudentInformation.nationalityQuestion == null
      || provitionalQuestionsStudentInformation.emailQuestion == "" || provitionalQuestionsStudentInformation.emailQuestion == null
      || provitionalQuestionsStudentInformation.cellPhoneQuestion == "" || provitionalQuestionsStudentInformation.cellPhoneQuestion == null
      || provitionalQuestionsStudentInformation.streetQuestion == "" || provitionalQuestionsStudentInformation.streetQuestion == null
    ) {
      alert(' Faltan preguntas obligatorias por completar')

    } else {

      const obj = { ...provitionalQuestionsStudentInformation, isComplete: true }
      // guardar info provisional
      await saveAnswers('provitionalQuestionsStudentInformation', obj);
      setHeaderActive(headers[1]);

    }

  }

  //  Validation from Academic information

  const validationNFromTabAcademicInformation = async () => {

    if (
      provitionalQuestionsAcademic.currentCollegeName == "" || provitionalQuestionsAcademic.currentCollegeName == null
      || provitionalQuestionsAcademic.yearGetIn == "" || provitionalQuestionsAcademic.yearGetIn == null
      || provitionalQuestionsAcademic.languagefromCurrentCollege == "" || provitionalQuestionsAcademic.languagefromCurrentCollege == null
      || provitionalQuestionsAcademic.currentGrade == "" || provitionalQuestionsAcademic.currentGrade == null
      || provitionalQuestionsAcademic.gradewished == "" || provitionalQuestionsAcademic.gradewished == null
      || provitionalQuestionsAcademic.ciclyWished == "" || provitionalQuestionsAcademic.ciclyWished == null
      || provitionalQuestionsAcademic.durationStudy == "" || provitionalQuestionsAcademic.durationStudy == null

    ) {

      alert(' Faltan preguntas obligatorias por completar')

    } else {

      // guardar info provisional
      const obj = { ...provitionalQuestionsAcademic, isComplete: true }
      await saveAnswers('provitionalQuestionsAcademic', obj);


      setHeaderActive(headers[2]);
    }

  }

  //  Validation from Family information

  const validationFromTabFamilyInformation = async () => {

    if (
      provitionalQuestionsFamily.nameFirstWarden == "" || provitionalQuestionsFamily.nameFirstWarden == null
      ||
      provitionalQuestionsFamily.lastNameFirstWarden == "" || provitionalQuestionsFamily.lastNameFirstWarden == null
      ||
      provitionalQuestionsFamily.secondLastNameFirstWarden == "" || provitionalQuestionsFamily.secondLastNameFirstWarden == null
      ||
      provitionalQuestionsFamily.relationshipWithStudentFirstWarden == "" || provitionalQuestionsFamily.relationshipWithStudentFirstWarden == null
      ||
      provitionalQuestionsFamily.emailFirstWarden == "" || provitionalQuestionsFamily.emailFirstWarden == null
      ||
      provitionalQuestionsFamily.phoneFirstWarden == "" || provitionalQuestionsFamily.phoneFirstWarden == null
      ||
      provitionalQuestionsFamily.jobFirstWarden == "" || provitionalQuestionsFamily.jobFirstWarden == null
      ||
      provitionalQuestionsFamily.stateCivilFirstWarden == "" || provitionalQuestionsFamily.stateCivilFirstWarden == null
    ) {
      alert(' Faltan preguntas obligatorias por completar')

    } else {

      //Guardar info provisional
      const obj = { ...provitionalQuestionsFamily, isComplete: true }

      await saveAnswers('provitionalQuestionsFamily', obj);

      setHeaderActive(headers[3]);

    }

  }


  const lookingNextHeader = async () => {


    // if active is 0 then valid secion 0 and return if someting fail or continue if all OK
    if (headerActive == headers[0]) {

      validationsFromTabStudentInformation();
    }

    // if active is 1 then valid seacion 1 and return if someting fail or continue if all OK

    if (headerActive == headers[1]) {

      validationNFromTabAcademicInformation();
    }

    if (headerActive == headers[2]) {

      validationFromTabFamilyInformation();


    }
    if (headerActive == headers[3]) {

      if (provitionalQuestionsStudentProfile.firstLanguage == "" || provitionalQuestionsStudentProfile.firstLanguage == null) {
        alert(' Faltan preguntas obligatorias por completar')
      } else {
        const obj = { ...provitionalQuestionsStudentProfile, isComplete: true }
       await saveAnswers('provitionalQuestionsStudentProfile', obj);
        handleClose();
      }


    }

  }

  const selectHeader = async (header) => {

    // setHeaderActive(header);

    // No validations , temporal

    if (header != headerActive) {

      // Validacion informacion estudiante

      if (headerActive == headers[0]) {

        if (
          provitionalQuestionsStudentInformation.firstNameQuestion == "" || provitionalQuestionsStudentInformation.firstNameQuestion == null
          || provitionalQuestionsStudentInformation.father_lastnameQuestion == "" || provitionalQuestionsStudentInformation.father_lastnameQuestion == null
          || provitionalQuestionsStudentInformation.birthdayQuestion == "" || provitionalQuestionsStudentInformation.birthdayQuestion == null
          || provitionalQuestionsStudentInformation.nationalityQuestion == "" || provitionalQuestionsStudentInformation.nationalityQuestion == null
          || provitionalQuestionsStudentInformation.emailQuestion == "" || provitionalQuestionsStudentInformation.emailQuestion == null
          || provitionalQuestionsStudentInformation.cellPhoneQuestion == "" || provitionalQuestionsStudentInformation.cellPhoneQuestion == null
          || provitionalQuestionsStudentInformation.streetQuestion == "" || provitionalQuestionsStudentInformation.streetQuestion == null
        ) {
          alert(' Faltan preguntas obligatorias por responder')
        } else {
          // guardar info provisional
          const obj = { ...provitionalQuestionsStudentInformation, isComplete: true }
         await saveAnswers('provitionalQuestionsStudentInformation', obj);
          setHeaderActive(header);

        }
      }

      // Validacion informacion Academica

      if (headerActive == headers[1]) {


        if (
          provitionalQuestionsAcademic.currentCollegeName == "" || provitionalQuestionsAcademic.currentCollegeName == null
          || provitionalQuestionsAcademic.yearGetIn == "" || provitionalQuestionsAcademic.yearGetIn == null
          || provitionalQuestionsAcademic.languagefromCurrentCollege == "" || provitionalQuestionsAcademic.languagefromCurrentCollege == null
          || provitionalQuestionsAcademic.currentGrade == "" || provitionalQuestionsAcademic.currentGrade == null
          || provitionalQuestionsAcademic.gradewished == "" || provitionalQuestionsAcademic.gradewished == null
          || provitionalQuestionsAcademic.ciclyWished == "" || provitionalQuestionsAcademic.ciclyWished == null
          || provitionalQuestionsAcademic.durationStudy == "" || provitionalQuestionsAcademic.durationStudy == null

        ) {

          alert('Faltan preguntas obligatorios por responder');

        } else {


          const obj = { ...provitionalQuestionsAcademic, isComplete: true }
          // guardar info provisional
          await saveAnswers('provitionalQuestionsAcademic', obj);
          setHeaderActive(header);

        }

      }
      else if (headerActive == headers[1]) {
        console.log('HOLA')
      }




      if (headerActive == headers[2]) {

        if (
          provitionalQuestionsFamily.nameFirstWarden == "" || provitionalQuestionsFamily.nameFirstWarden == null
          ||
          provitionalQuestionsFamily.lastNameFirstWarden == "" || provitionalQuestionsFamily.lastNameFirstWarden == null
          ||
          provitionalQuestionsFamily.secondLastNameFirstWarden == "" || provitionalQuestionsFamily.secondLastNameFirstWarden == null
          ||
          provitionalQuestionsFamily.relationshipWithStudentFirstWarden == "" || provitionalQuestionsFamily.relationshipWithStudentFirstWarden == null
          ||
          provitionalQuestionsFamily.emailFirstWarden == "" || provitionalQuestionsFamily.emailFirstWarden == null
          ||
          provitionalQuestionsFamily.phoneFirstWarden == "" || provitionalQuestionsFamily.phoneFirstWarden == null
          ||
          provitionalQuestionsFamily.jobFirstWarden == "" || provitionalQuestionsFamily.jobFirstWarden == null
          ||
          provitionalQuestionsFamily.stateCivilFirstWarden == "" || provitionalQuestionsFamily.stateCivilFirstWarden == null
        ) {
          alert('Faltan preguntas obligatorios por responder');

        } else {
          //  Guardar info provisional
          const obj = { ...provitionalQuestionsFamily, isComplete: provitionalQuestionsFamily.numberFamily != null ? true : false }

          await saveAnswers('provitionalQuestionsFamily', obj);

          setHeaderActive(header);
        }


      }

      if (headerActive == headers[3]) {


        if (provitionalQuestionsStudentProfile.firstLanguage == "" || provitionalQuestionsStudentProfile.firstLanguage == null) {
          alert(' Faltan preguntas obligatorias por completar')
        } else {

          // guardar info provisional
          const obj = { ...provitionalQuestionsStudentProfile, isComplete: true }

          await saveAnswers('provitionalQuestionsStudentProfile', obj);
          setHeaderActive(header);
        }



      }

    }
    // setHeaderActive(header);

    // // setHeaderActive(header);
  }

  const getStudent = (e) => {

    setNewStudent((e.name ?? '') + ' ' + (e.father_name ?? ' ') + ' ' + (e.mother_name ?? ''));
  }

  const renderSwitchHeader = (header, isNew = false) => {


    // const headers = ['INFORMACION DEL ESTUDIANTE', 'INFORMACIÓN ACADEMICA', 'INFORMACIÓN FAMILIAR', 'PERFIL DEL ESTUDIANTE'];



    switch (header) {
      case 'INFORMACIÓN DEL ESTUDIANTE':

        return <TabStudentInformation title={header} />;

      case 'INFORMACIÓN ACADEMICA':
        return <TabAcademicInformation title={header} />;


      case 'INFORMACIÓN FAMILIAR':
        return <TabFamilyInformation title={header} />;

      case 'PERFIL DEL ESTUDIANTE':
        return <TabProfileInformation title={header} />;

      default:
        // return <NewStudent getStudent={getStudent} title={'New Student'} />;

    }

  }
  return (
    <Modal show={props.openModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className={styles.backgroundImageModal}>
            <img src={modalLogo} width={450} height={450} alt="Background modal image" />
          </div>

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className={styles.boxContent}>

          {!props.newStudent &&
            <div className={styles.headerBox}>
              {headers.map(header => {
                return (
                  <button  key={header} onClick={(e) => selectHeader(header)}
                    style={{ textTransform: 'uppercase', marginRight: '10px' }}
                    className={headerActive == header ? styles.headerActive : styles.header}
                  >
                    {header}
                  </button>
                )
              })}

            </div>
          }

          {
            renderSwitchHeader(props.newStudent ? 'New Student ' : headerActive)
          }

          <hr />
          <div className={styles.centerDiv}>
            <button onClick={(e) => lookingNextHeader(e)} className={styles.buttonSaveAndContinue}>SAVE AND CONTINUE</button>
          </div>

        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button className={styles.modalCloseBtn} onClick={handleClose}>
          X
        </Button>
      </Modal.Footer>
    </Modal>

  )
}
