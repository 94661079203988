import styles from '../../../styles/Landing/Landing.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsProgress, faExclamation, faExclamationCircle, faMailBulk, faPencil } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import ModalStudent from './modalsQuestions/ModalStudent';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setActiveApplicant } from '../../../app/features/Applicants/applicantsSlice';
import { Cookies } from 'react-cookie';



export default function Questions() {
    const dispatch = useDispatch();

    const { activeApplicant } = useSelector(state => state.applicants);

    const [currentHeader, setCurrentHeader] = useState('Inf. Academica');

    const cookies = new Cookies();

    const userLogged = cookies.get('UserLogged')


    console.log('activeApplicant',activeApplicant);


    const [modalStudent, setModalStudent] = useState(false);

    const sendingEmail = async () => {
        const objToEmail = {
            applicant: activeApplicant,
            user : userLogged
        };

        const response = await axios.post('https://xtendapi.boardingschools.mx/api/applicants/sendingEmail', objToEmail);
        if( response.status == 200 ){
            alert( 'Formulario enviado correctamente' );
            console.log('Response',response);

        } else {
            alert( 'Ocurrio un error' );
        }

        
        // alert ( 'Enviando por correo...' )} 
    }

    const openModalStudent = (header) => {
        setCurrentHeader(header);
        setModalStudent(!modalStudent);
    }
    const closeModal = async () => {


        try {
            const response = await axios.post('https://xtendapi.boardingschools.mx/api/applicants/getUpdatedApplicant', { id_applicant: activeApplicant.id });
            dispatch(setActiveApplicant(response.data[0], 'Activate Applicant'))


            // dispatch(setApplicantsList(response.data, 'ListApplicants'))

            // alert('Aplicante creado con exito.')

            // props.closeModalNewStudent(false);

        } catch (error) {
            alert('Ocurrio un error');
        }

        setModalStudent(!modalStudent);
    }

    return (
        <>
            <ModalStudent closeModal={closeModal} header={currentHeader} openModal={modalStudent} />
           


            <div className={styles.questionsBox}>

<div className={styles.firstColumnQuestion}>

    <h1 className={styles.titleQuestion}>   <FontAwesomeIcon
        icon={faBarsProgress}
    />  {activeApplicant.questionary__student__information.title}</h1>

</div>



<div className={styles.boxIscomplete} >
    {activeApplicant.questionary__student__information.isComplete ?

        <span className={styles.optionalField}>
            &nbsp;
            Complete
        </span>

        :

        <span className={styles.lastColumnQuestion}>
            <FontAwesomeIcon
                icon={faExclamationCircle}
            />&nbsp;
            Pendiente
        </span>

    }
</div>
<span >
    Aqui ira una descripción de la informacion del estudiante
</span>
<button onClick={(e) => openModalStudent('INFORMACIÓN DEL ESTUDIANTE')} className={styles.buttonCompleteInfo}>
    <FontAwesomeIcon
        icon={faPencil}
    />&nbsp;COMPLETA LA {activeApplicant.questionary__student__information.title}</button>
</div>


            <div className={styles.questionsBox}>

                <div className={styles.firstColumnQuestion}>

                    <h1 className={styles.titleQuestion}>   <FontAwesomeIcon
                        icon={faBarsProgress}
                    />  {activeApplicant.questionary__academic__information.title}</h1>

                </div>



                <div className={styles.boxIscomplete} >
                {activeApplicant.questionary__academic__information.isComplete ?

                        <span className={styles.optionalField}>
                            &nbsp;
                            Complete
                        </span>

                        :

                        <span className={styles.lastColumnQuestion}>
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                            />&nbsp;
                            Pendiente
                        </span>

                    }
                </div>
                <span >
                    Lorem ipsum dolor sit amet
                    consectetur adipisicing elit. Nesciunt quisquam
                    quod quam autem voluptatibus maiores ducimus modi,
                    mollitia saepe nobis sit corporis sequi exercitationem a ad illo? Nemo, explicabo dolorum.
                </span>
                <button onClick={(e) => openModalStudent('INFORMACIÓN ACADEMICA')} className={styles.buttonCompleteInfo}>
                    <FontAwesomeIcon
                        icon={faPencil}
                    />&nbsp;COMPLETA LA {activeApplicant.questionary__academic__information.title}</button>
            </div>



            <div className={styles.questionsBox}>

                <div className={styles.firstColumnQuestion}>

                    <h1 className={styles.titleQuestion}>   <FontAwesomeIcon
                        icon={faBarsProgress}
                    />  {activeApplicant.questionary__family__information.title}</h1>

                </div>



                <div className={styles.boxIscomplete} >
                {activeApplicant.questionary__family__information.isComplete ?

                        <span className={styles.optionalField}>
                            &nbsp;
                            Complete
                        </span>

                        :

                        <span className={styles.lastColumnQuestion}>
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                            />&nbsp;
                            Pendiente
                        </span>

                    }
                </div>
                <span >
                    Lorem ipsum dolor sit amet
                    consectetur adipisicing elit. Nesciunt quisquam
                    quod quam autem voluptatibus maiores ducimus modi,
                    mollitia saepe nobis sit corporis sequi exercitationem a ad illo? Nemo, explicabo dolorum.
                </span>

                <button onClick={(e) => openModalStudent('INFORMACIÓN FAMILIAR')} className={styles.buttonCompleteInfo}>
                    <FontAwesomeIcon
                        icon={faPencil}
                    />&nbsp;COMPLETA LA {activeApplicant.questionary__family__information.title}</button>
            </div>


          

            <div className={styles.questionsBox}>

                <div className={styles.firstColumnQuestion}>

                    <h1 className={styles.titleQuestion}>   <FontAwesomeIcon
                        icon={faBarsProgress}
                    />  {activeApplicant.questionary__student__profile.title}</h1>

                </div>



                <div className={styles.boxIscomplete} >
                {activeApplicant.questionary__student__profile.isComplete ?

                        <span className={styles.optionalField}>
                            &nbsp;
                            Complete
                        </span>

                        :

                        <span className={styles.lastColumnQuestion}>
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                            />&nbsp;
                            Pendiente
                        </span>

                    }
                </div>
                <span >
                    Lorem ipsum dolor sit amet
                    consectetur adipisicing elit. Nesciunt quisquam
                    quod quam autem voluptatibus maiores ducimus modi,
                    mollitia saepe nobis sit corporis sequi exercitationem a ad illo? Nemo, explicabo dolorum.
                </span>
                <button onClick={(e) => openModalStudent('PERFIL DEL ESTUDIANTE')} className={styles.buttonCompleteInfo}>
                    <FontAwesomeIcon
                        icon={faPencil}
                    />&nbsp;COMPLETA EL {activeApplicant.questionary__student__profile.title}</button>
            </div>



            {/* HEEEEEEEEEEEEEEEEEEEEEEEEE */}

            <div style={{display:'flex',justifyContent:'right'}} >
                {
                (activeApplicant.questionary__student__profile.isComplete && activeApplicant.questionary__academic__information.isComplete && activeApplicant.questionary__family__information.isComplete
                    && activeApplicant.questionary__student__information.isComplete
                 ) ?

                 <span onClick={(e) => sendingEmail()} style={{marginBottom:'10px',background:'purple'}}
                 className={styles.lastColumnQuestion}>
                     <FontAwesomeIcon
                         icon={faMailBulk}
                     />&nbsp;
                     Enviar por correo
                 </span>

                    :

                    <span  disabled style={{marginBottom:'10px',background:'gray' , cursor:'none'}}
                    className={styles.lastColumnQuestion}>
                        <FontAwesomeIcon
                            icon={faMailBulk}
                        />&nbsp;
                        Enviar por correo
                    </span>

                }
            </div>


        </>

    )
}
