import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
   listApplicants:{},
   activeApplicant : {},
   provitionalQuestionsAcademic : {},
   provitionalQuestionsFamily : {},
   provitionalQuestionsStudentInformation : {},
   provitionalQuestionsStudentProfile: {},

}
export  const applicantsSlice = createSlice({
    name: 'applicants',
    initialState,
    reducers : {
        saveProvitionalQuestionsAcademic: (state , action) =>{
            
            state.provitionalQuestionsAcademic = action.payload;

        },
        saveProvitionalQuestionsFamily: (state , action) =>{
            
            state.provitionalQuestionsFamily = action.payload;

        },
        saveProvitionalQuestionsStudentInfo: (state , action) =>{
            
            state.provitionalQuestionsStudentInformation = action.payload;

        },
        saveProvitionalQuestionsStudentProfile: (state , action) =>{
            
            state.provitionalQuestionsStudentProfile = action.payload;

        },
        setApplicantsList: ( state , action ) => {

            state.listApplicants = action.payload;
        },

        setActiveApplicant: ( state , action) => {
            state.activeApplicant = action.payload
        }

    }

});

export const { setApplicantsList, setActiveApplicant , saveProvitionalQuestionsAcademic , saveProvitionalQuestionsFamily, saveProvitionalQuestionsStudentInfo,saveProvitionalQuestionsStudentProfile } = applicantsSlice.actions;
export default applicantsSlice.reducer;