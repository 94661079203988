import React, { useEffect, useState } from 'react'
import styles from '../../styles/Landing/Landing.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import ImageCollege from '../../files/collegeTwo.jpg'
import LogoTest from '../../files/logoTest.png'
import ProfilePicture from '../../files/profilePicture.png'


import Questions from '../../components/AdmissionPortal/LandingPage/Questions';
import ModalStudent from '../../components/AdmissionPortal/LandingPage/modalsQuestions/ModalStudent';
import { faArrowRotateBackward } from '@fortawesome/free-solid-svg-icons/faArrowRotateBackward';
import Cookies from 'universal-cookie';
import BeatLoader from 'react-spinners/BeatLoader';
import NewStudentModal from '../../components/AdmissionPortal/LandingPage/newStudentModal/NewStudentModal';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setActiveApplicant, setApplicantsList } from '../../app/features/Applicants/applicantsSlice';
import BSMWhiteTransparent from '../../files/BSM Logo blanco fondo transparente.png';
import CoverIndex from '../../files/coverindex7-1920x1080.png';


export default function LandingPage() {



    const { listApplicants, activeApplicant } = useSelector(state => state.applicants);


    const cookies = new Cookies();
    const userLogged = cookies.get('UserLogged')

    const [logoutLoading, setLogoutLoading] = useState(false);
    const navigate = useNavigate();
    const [edit, setEdit] = useState(false);
    const [profileActive, setProfileActive] = useState([]);
    const [modalStudent, setModalStudent] = useState(false);

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const dispatch = useDispatch();

    const getApplicants = async (byId) => {
        try {
            const response = await axios.post('https://xtendapi.boardingschools.mx/api/applicants/getApplicantsbyUser', { id_user: byId });
            console.log('Responsexxx', response.data);

            dispatch(setApplicantsList(response.data, 'ListApplicants'))

            // alert('Aplicante creado con exito.')

            // props.closeModalNewStudent(false);

        } catch (error) {
            alert('Ocurrio un error');
        }
    }


    const checkIfExistSession = () => {

        if(userLogged){
            console.log('Form funcionando...')
        } else {
            window.location.reload(); 
        }
        // {userLogged?.name}
    }
    useEffect(()=>{

        checkIfExistSession();

        
    },[userLogged])



    useEffect(() => {

        // load applicants by first time
        if (userLogged?.id) {
            getApplicants(userLogged.id);
        }


    }, [])



    const deletingApplicant = async (applicant) => {

        const cadn = "Realmente desea eliminar a " + applicant.name + "?";
        const answer = window.confirm(cadn);

        if (answer) {
            try {
                const response = await axios.post('https://xtendapi.boardingschools.mx/api/applicants/deletingApplicant', { id_user: applicant.id_user, id_applicant: applicant.id });

                // dispatch(setApplicantsList(response.data, 'ListApplicants'))
                getApplicants(applicant.id_user);

                // alert('Aplicante creado con exito.')

                // props.closeModalNewStudent(false);

            } catch (error) {
                alert('Ocurrio un error');
            }
        }
        else {
            //some code
        }


    }

    const closeModal = (e) => {

        console.log('CERRANDOOOOOOOOOOOO')

        if (e) {
            alert(' nuevo perfil configurado');
            setProfileActive({ name: e })
            // setTestApplications(prevArray => [...testApplications, { name: e }]);
        }
        setModalStudent(!modalStudent);
        setEdit(!edit);
    }

    const openModalStudent = () => {
        setModalStudent(!modalStudent);
    }

    const closeModalNewStudent = (flag) => {

        console.log('HEREEEEEEEEE CLOSE');
        setModalStudent(flag);

    }
    const handleLogout = async () => {
        setLogoutLoading(true);
        await delay(2000);
        cookies.remove('token', { path: '/' });
        cookies.remove('UserLogged', { path: '/' });
        navigate('/AdmissionPortal')
        dispatch(setApplicantsList([], 'ListApplicants'))
        setLogoutLoading(false);
    }

    const handleNewApplicant = () => {
        openModalStudent();
        // if(edit == true){
        //     setEdit(false);

        // } else {
        //     setEdit(true)
        // }

        // setEdit(!edit);
    }

    const backToBegin = () => {
        setModalStudent(false);
        setEdit(false)
    }


    const startToEdit = (profile) => {

        dispatch(setActiveApplicant(profile, 'Activate Applicant'))

        // setProfileActive(profile);
        setEdit(!edit);

    }

    const getStudent = () => {

    }

    return (

        // workArea
        <div className={styles.workArea}>
            <NewStudentModal closeModalNewStudent={closeModalNewStudent} openModal={modalStudent} />
            {/* <ModalStudent getStudent={getStudent} newStudent={true} closeModal={closeModal} openModal={modalStudent} /> */}

            {/* box user panel right and logout button */}
            <div className={styles.miniPanelRight}>

                <button className={styles.buttonEmailInfo}>
                    <FontAwesomeIcon
                        icon={faUser}
                        style={{ marginRight: '4px' }}
                    />
                    {userLogged?.email}
                </button>

                <button onClick={handleLogout} className={styles.buttonCloseSession}>
                    {logoutLoading ?

                        <BeatLoader color='#395a8b' size={8} />

                        :
                        "                Cerrar session"
                    }
                </button>

            </div>
            {/* end of box user panel */}

            {/* image cover up */}
            <div className={styles.imageCoverUp}>

                {/* <img src={CoverIndex} alt="Background college up" /> */}
                {/* 
                <Image
                    src="/images/collegeTwo.jpg"
                    layout='fill'
                    alt="Background college up" /> */}

            </div>
            {/* end image cover up */}

            {/* main Box applicant  */}
            <div className={styles.mainBox}>


                {/* end headerLogoImage div  */}
                <div className={styles.boxApplicationfirstRow}>
                    <div className={styles.boxImage}>

                        <img  src={BSMWhiteTransparent} alt="Background college up" width={247} height={247} />


                        {/* <Image
    width={247}
    height={247}
    src="/logoTest.png"
    alt="Background college up" /> */}

                    </div>
                </div>

                <div className={styles.boxApplication}>

                    {/* two columns */}
                    {/* column left Applicants */}

                    <div className={styles.leftBox}>
                        <div className={styles.newBoxLeft}>
                            <div className={styles.titleHello}>¡HOLA!</div>
                            <div className={styles.titleWelcome}>BIENVENIDO</div>
                            <div className={styles.boxApplicant}>
                                {userLogged?.name}
                            </div>
                            {!edit ?
                                <div className={styles.boxAddnewApplicant}>

                                    {/* <NewApplicant /> */}
                                    <button onClick={(e) => handleNewApplicant(e)} className={styles.newButtonAddApplicant}>
                                        +  ADD NEW APPLICANT
                                    </button>

                                </div>
                                :

                                <div className={styles.boxAddnewApplicant}>

                                    {/* <NewApplicant /> */}
                                    <button onClick={backToBegin} className={styles.newButtonAddApplicant}>
                                        <FontAwesomeIcon
                                            icon={faArrowRotateBackward}
                                        /> Go Back
                                    </button>

                                </div>
                            }
                        </div>

                        {/* first column title Applicants */}
                        {/* <div className={styles.firstColumn}>
                            <h2>
                                Applicants
                            </h2>
                        </div> */}

                        {/* second column information of user and profile image */}




                    </div>

                    {/* column right Admission Portal */}
                    <div className={styles.rightBox}>

                        <div className={styles.containerBoxRight}>

                            <div>
                                {edit ?
                                    <>
                                        <div className={styles.titleAdmission}>
                                            ADMISSION PORTAL FOR 
                                            <p style={{fontSize:'40px',fontWeight:'400'}}>
                                            {activeApplicant.name} {activeApplicant.father_name} {activeApplicant.mother_lastname}
                                            </p>
                                        </div>

                                        <div className={styles.contentAdmission}>
                                            <Questions />


                                        </div>
                                    </>

                                    :

                                    <>
                                        <div className={styles.titleAdmission}>
                                            ADMISSION PORTAL
                                        </div>
                                        
                                        <div className={styles.contentAdmission}>
                                            Listado de aplicantes
                                        </div>
                                        { !modalStudent ?
                                      
                                        [listApplicants[0]
                                            ?
                                            [listApplicants.map((applicant,index) => {
                                                return (
                                                    <div key={index+'div'} className={styles.provitionalName}>
                                                        <div key={index+'icon'} onClick={(e) => startToEdit(applicant)} className={styles.buttonApplicants}>
                                                            <FontAwesomeIcon
                                                                icon={faUser}
                                                                
                                                                /> {applicant.name} {applicant.father_name} {applicant.mother_lastname}
                                                        </div>
                                                        <div key={index+'delete'}onClick={(e) => deletingApplicant(applicant)}
                                                            className={styles.buttonDeleteApplicants}> x </div>

                                                    </div>

                                                )
                                            })]
                                            :
                                            
                                            <div key={"nobody"} className={styles.contentAdmission}>

                                                Sin aplicantes aun</div>

                                        ]
                                        :
                                        <div>
                                            
                                        </div>
                                        }
                                    </>

                                }
                            </div>

                        </div>


                    </div>


                </div>


            </div>

        </div>
    )
}


