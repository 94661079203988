import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
   userLogged:{}

}
export  const sessionSlice = createSlice({
    name: 'authSession',
    initialState,
    reducers : {
        
        setAuthSession: ( state , action ) => {
            state.userLogged = {...action.payload}

        }

    }

});

export const { setAuthSession } = sessionSlice.actions;
export default sessionSlice.reducer;