import { Outlet , Navigate } from "react-router-dom";
import Cookies from 'universal-cookie';


// Route protected , if exist a token continue else return to admissionPortal

const ProtectedRoutes = () => {
    const cookies = new Cookies();

    const userLogged = cookies.get('UserLogged')


    return userLogged?.token ?  < Outlet /> : < Navigate to ="/AdmissionPortal" />



}

export default ProtectedRoutes;