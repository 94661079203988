import React, { useEffect, useState } from 'react'
import styles from '../../../../../styles/modal/ModalStudent.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { saveProvitionalQuestionsStudentInfo } from '../../../../../app/features/Applicants/applicantsSlice';

export default function TabStudentInformation( {title} ) {

    const { listApplicants, activeApplicant , provitionalQuestionsStudentInformation } = useSelector(state => state.applicants);
    console.log('PROVIT',provitionalQuestionsStudentInformation);

    console.log('ACTIVEAP',activeApplicant);
    const [answersAcademic,setAnswersAcademic] = useState(activeApplicant.questionary__student__information);


    // const [answersAcademic,setAnswersAcademic] = useState({


    //     'firstNameQuestion': provitionalQuestionsStudentInformation.firstNameQuestion ?? activeApplicant.questionary__student__information.firstNameQuestion?? null,
    //     'secondNameQuestion': provitionalQuestionsStudentInformation.secondNameQuestion ?? activeApplicant.questionary__student__information.secondNameQuestion?? null,
    //     'father_lastnameQuestion': provitionalQuestionsStudentInformation.father_lastnameQuestion ?? activeApplicant.questionary__student__information.father_lastnameQuestion?? null,
    //     'mother_lastnameQuestion': provitionalQuestionsStudentInformation.mother_lastnameQuestion ?? activeApplicant.questionary__student__information.mother_lastnameQuestion?? null,
    //     'birthdayQuestion': provitionalQuestionsStudentInformation.birthdayQuestion ?? activeApplicant.questionary__student__information.birthdayQuestion?? null,
    //     'nationalityQuestion': provitionalQuestionsStudentInformation.nationalityQuestion ?? activeApplicant.questionary__student__information.nationalityQuestion?? null,
    //     'emailQuestion': provitionalQuestionsStudentInformation.emailQuestion ?? activeApplicant.questionary__student__information.emailQuestion?? null,
    //     'cellphoneQuestion': provitionalQuestionsStudentInformation.cellphoneQuestion ?? activeApplicant.questionary__student__information.cellphoneQuestion?? null,
    //     'streetQuestion': provitionalQuestionsStudentInformation.streetQuestion ?? activeApplicant.questionary__student__information.streetQuestion?? null,
    //     'colonyQuestion': provitionalQuestionsStudentInformation.colonyQuestion ?? activeApplicant.questionary__student__information.colonyQuestion?? null,
    //     'cityQuestion': provitionalQuestionsStudentInformation.cityQuestion ?? activeApplicant.questionary__student__information.cityQuestion?? null,
    //     'zipCodeQuestion': provitionalQuestionsStudentInformation.zipCodeQuestion ?? activeApplicant.questionary__student__information.zipCodeQuestion?? null,
    //     'stateQuestion': provitionalQuestionsStudentInformation.stateQuestion ?? activeApplicant.questionary__student__information.stateQuestion?? null,
    //     'countryQuestion': provitionalQuestionsStudentInformation.countryQuestion ?? activeApplicant.questionary__student__information.countryQuestion?? null,
    //     'id' : provitionalQuestionsStudentInformation.id ?? 0

        
        
        
    

    //     // 'questionaryId' : activeApplicant.questionary__student__information.id,
    //     // 'emailQuestion' : provitionalQuestionsStudentInformation.emailQuestion ??activeApplicant.questionary__student__information.emailQuestion??null,
    //     // 'nationalityQuestion' : provitionalQuestionsStudentInformation.nationalityQuestion ?? activeApplicant.questionary__student__information.nationalityQuestion??null,
    //     // 'rbQuestion' : provitionalQuestionsStudentInformation.rbQuestion ?? activeApplicant.questionary__student__information.rbQuestion?? null,
    //     // 'fieldRequired': 2,
    //     // 'firstNameQuestion': provitionalQuestionsStudentInformation.rbQuestion ?? activeApplicant.questionary__student__information.rbQuestion?? null,


        
    // });

    const dispatch = useDispatch();


    useEffect(() =>{

        dispatch ( saveProvitionalQuestionsStudentInfo ( answersAcademic , 'Saving provitional Questions'));

    },[answersAcademic])


   

    const handleChange =  (e) => {


        setAnswersAcademic({
            ...answersAcademic,
            [e.target.name] : e.target.value
         });

    }

    return (
        <div>

            <div className={styles.boxQuestions}>
                <h1 className={styles.titleQuestions} >{ activeApplicant.questionary__student__information.title }</h1>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Primer nombre *</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="firstNameQuestion"   value={answersAcademic.firstNameQuestion} onChange={(e) =>  handleChange(e)} />
                        
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Segundo nombre   </label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="secondNameQuestion"   value={answersAcademic.secondNameQuestion} onChange={(e) =>  handleChange(e)} />
                        
                    </div>
                </div>
            </div>


            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Apellido paterno*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="father_lastnameQuestion"   value={answersAcademic.father_lastnameQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>



            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Apellido materno</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="mother_lastnameQuestion"   value={answersAcademic.mother_lastnameQuestion} onChange={(e) =>  handleChange(e)} />
                        </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Fecha de nacimiento*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="date" name="birthdayQuestion"   value={answersAcademic.birthdayQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Nacionalidad (es)*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="nationalityQuestion"   value={answersAcademic.nationalityQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Email*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="emailQuestion"   value={answersAcademic.emailQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Celular*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="phone" name="cellPhoneQuestion"   value={answersAcademic.cellPhoneQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


                <div style={{marginBottom:'10px'}}className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>⁠Dirección</label>
                    </div>
                    {/* <div className={styles.inputBox}>
                        <input type="text" name="emailQuestion"   value={answersAcademic.emailQuestion} onChange={(e) =>  handleChange(e)} />
                    </div> */}
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>⁠Calle y número*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="streetQuestion"   value={answersAcademic.streetQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>⁠Colonia</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="colonyQuestion"   value={answersAcademic.colonyQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Ciudad</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="cityQuestion"   value={answersAcademic.cityQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Estado</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="stateQuestion"   value={answersAcademic.stateQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>CP</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="zipCodeQuestion"   value={answersAcademic.zipCodeQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>País</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="countryQuestion"   value={answersAcademic.countryQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>





            {/* <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Nationality</label>
                    </div>
                    <div className={styles.inputBox}>
                        <select name="nationalityQuestion"  value={answersAcademic.nationalityQuestion}  onChange={(e) =>  handleChange(e)} id="pet-select">
                            <option value="US">United States</option>
                            <option value="alb">Albania</option>
                            <option value="mex">Mexico</option>
                            <option value="italian">Italian</option>
                            <option value="uk">United Kingdom</option>
                        </select>

                    </div>
                </div>
            </div> */}






            {/* <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Is the applicant a candidate for financial aid? * </label>
                    </div>
                    <div className={styles.inputBox}>
                        <fieldset>

                            <div>
                                <input type="radio" id="huey" onClick={(e) => handleChange(e)} name="rbQuestion" checked={answersAcademic.rbQuestion == 1 ? true : false } value={1}  />
                                <label style={{ marginLeft: '5px' }} for="huey">Yes</label>
                            </div>

                            <div>
                                <input type="radio" id="dewey" onClick={(e) => handleChange(e)} name="rbQuestion" checked={answersAcademic.rbQuestion == 0 ? true : false } value={0}/>
                                <label style={{ marginLeft: '5px' }} for="dewey">No</label>
                            </div>


                        </fieldset>

                    </div>
                </div>
            </div> */}




        </div>
    )
}
