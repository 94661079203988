import { Outlet , Navigate } from "react-router-dom";
import Cookies from 'universal-cookie';


// Route public , but  if exist a token redirect to Landing page  else return to Login form


const PublicRoutes = () => {
    const cookies = new Cookies();

    const userLogged = cookies.get('UserLogged')

    return userLogged?.token ? < Navigate to ="/AdmissionPortal/Landing" />  : < Outlet />



}

export default PublicRoutes;